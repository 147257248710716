export const Settings = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9999 8.68994V3.99994H15.3099L11.9999 0.689941L8.68994 3.99994H3.99994V8.68994L0.689941 11.9999L3.99994 15.3099V19.9999H8.68994L11.9999 23.3099L15.3099 19.9999H19.9999V15.3099L23.3099 11.9999L19.9999 8.68994ZM11.9999 17.9999C8.68994 17.9999 5.99994 15.3099 5.99994 11.9999C5.99994 8.68994 8.68994 5.99994 11.9999 5.99994C15.3099 5.99994 17.9999 8.68994 17.9999 11.9999C17.9999 15.3099 15.3099 17.9999 11.9999 17.9999ZM11.9999 7.99994C9.78994 7.99994 7.99994 9.78994 7.99994 11.9999C7.99994 14.2099 9.78994 15.9999 11.9999 15.9999C14.2099 15.9999 15.9999 14.2099 15.9999 11.9999C15.9999 9.78994 14.2099 7.99994 11.9999 7.99994Z"
      fill="currentColor"
    />
  </svg>
);
