const Identification = () => {
  return (
    <div className="content_container identification_container">
      <div className="header">
        <p className="title">Identification</p>
      </div>

      <div className="content"></div>
    </div>
  );
};

export default Identification;
