export const Verified = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3334 7L13.7067 5.14L13.9334 2.68L11.5267 2.13333L10.2667 0L8.00008 0.973333L5.73341 0L4.47341 2.12667L2.06675 2.66667L2.29341 5.13333L0.666748 7L2.29341 8.86L2.06675 11.3267L4.47341 11.8733L5.73341 14L8.00008 13.02L10.2667 13.9933L11.5267 11.8667L13.9334 11.32L13.7067 8.86L15.3334 7ZM6.72675 10.1467L4.19341 7.60667L5.18008 6.62L6.72675 8.17333L10.6267 4.26L11.6134 5.24667L6.72675 10.1467Z"
      fill="#3AA76D"
    />
  </svg>
);
