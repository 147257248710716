const Email = () => {
  return (
    <div className="content_container email_container">
      <div className="header">
        <p className="title">Email</p>
      </div>

      <div className="content"></div>
    </div>
  );
};

export default Email;
