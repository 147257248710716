export const Cart = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83337 15.5001C4.91671 15.5001 4.17504 16.2501 4.17504 17.1667C4.17504 18.0834 4.91671 18.8334 5.83337 18.8334C6.75004 18.8334 7.50004 18.0834 7.50004 17.1667C7.50004 16.2501 6.75004 15.5001 5.83337 15.5001ZM14.1667 15.5001C13.25 15.5001 12.5084 16.2501 12.5084 17.1667C12.5084 18.0834 13.25 18.8334 14.1667 18.8334C15.0834 18.8334 15.8334 18.0834 15.8334 17.1667C15.8334 16.2501 15.0834 15.5001 14.1667 15.5001ZM6.75004 11.3334H12.9584C13.5834 11.3334 14.1334 10.9917 14.4167 10.4751L17.5 4.63341L16.0417 3.83341L12.9584 9.66675H7.10837L3.55837 2.16675H0.833374V3.83341H2.50004L5.50004 10.1584L4.37504 12.1917C3.76671 13.3084 4.56671 14.6667 5.83337 14.6667H15.8334V13.0001H5.83337L6.75004 11.3334ZM10 2.16675L13.3334 5.50008L10 8.83342L8.82504 7.65842L10.1417 6.33342H6.66671V4.66675H10.1417L8.81671 3.34175L10 2.16675Z"
      fill="currentColor"
    />
  </svg>
);
