export const LinkedIn = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66666 4.82982C1.66666 4.01737 1.9894 3.2382 2.56389 2.66371C3.13837 2.08923 3.91754 1.76648 4.72999 1.76648H35.2667C35.6693 1.76583 36.0681 1.84459 36.4402 1.99826C36.8124 2.15193 37.1506 2.3775 37.4354 2.66204C37.7203 2.94659 37.9462 3.28452 38.1003 3.6565C38.2543 4.02848 38.3335 4.42719 38.3333 4.82982V35.3665C38.3338 35.7692 38.2548 36.168 38.1009 36.5402C37.9471 36.9123 37.7214 37.2505 37.4367 37.5353C37.152 37.8202 36.814 38.0461 36.4419 38.2001C36.0698 38.3542 35.671 38.4334 35.2683 38.4331H4.72999C4.32757 38.4331 3.92909 38.3539 3.55732 38.1998C3.18555 38.0457 2.84778 37.82 2.5633 37.5353C2.27882 37.2507 2.05321 36.9128 1.89936 36.5409C1.74551 36.1691 1.66644 35.7706 1.66666 35.3681V4.82982ZM16.18 15.7465H21.145V18.2398C21.8617 16.8065 23.695 15.5165 26.45 15.5165C31.7317 15.5165 32.9833 18.3715 32.9833 23.6098V33.3131H27.6383V24.8031C27.6383 21.8198 26.9217 20.1365 25.1017 20.1365C22.5767 20.1365 21.5267 21.9515 21.5267 24.8031V33.3131H16.18V15.7465ZM7.01332 33.0848H12.36V15.5165H7.01332V33.0831V33.0848ZM13.125 9.78648C13.1351 10.2443 13.0536 10.6995 12.8854 11.1253C12.7172 11.5512 12.4656 11.9392 12.1454 12.2665C11.8252 12.5939 11.4428 12.8539 11.0207 13.0315C10.5987 13.209 10.1454 13.3005 9.68749 13.3005C9.22959 13.3005 8.7763 13.209 8.35423 13.0315C7.93216 12.8539 7.5498 12.5939 7.2296 12.2665C6.9094 11.9392 6.65781 11.5512 6.48959 11.1253C6.32137 10.6995 6.23991 10.2443 6.24999 9.78648C6.26978 8.88791 6.64063 8.0328 7.28313 7.40429C7.92563 6.77578 8.7887 6.42385 9.68749 6.42385C10.5863 6.42385 11.4493 6.77578 12.0918 7.40429C12.7343 8.0328 13.1052 8.88791 13.125 9.78648Z"
      fill="white"
    />
  </svg>
);
