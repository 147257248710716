export const Search = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0668 13.6001L12.1335 10.6668C12.8669 9.6001 13.3334 8.33345 13.3334 6.93345C13.3334 3.46678 10.4667 0.600098 7.00008 0.600098C3.53341 0.600098 0.666748 3.46678 0.666748 6.93345C0.666748 10.4001 3.53341 13.2668 7.00008 13.2668C8.40008 13.2668 9.66681 12.8001 10.7335 12.0668L13.6667 15.0001L15.0668 13.6001ZM2.66675 7.0001C2.66675 4.6001 4.60008 2.66677 7.00008 2.66677C9.40008 2.66677 11.3334 4.6001 11.3334 7.0001C11.3334 9.4001 9.40008 11.3334 7.00008 11.3334C4.60008 11.3334 2.66675 9.4001 2.66675 7.0001Z"
      fill="black"
    />
  </svg>
);
