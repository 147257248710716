export const Identification = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 5H13V2C13 0.9 12.1 0 11 0H9C7.9 0 7 0.9 7 2V5H2C0.9 5 0 5.9 0 7V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V7C20 5.9 19.1 5 18 5ZM7 10C7.83 10 8.5 10.67 8.5 11.5C8.5 12.33 7.83 13 7 13C6.17 13 5.5 12.33 5.5 11.5C5.5 10.67 6.17 10 7 10ZM10 16H4V15.25C4 14.25 6 13.75 7 13.75C8 13.75 10 14.25 10 15.25V16ZM11 7H9V2H11V7ZM16 14.5H12V13H16V14.5ZM16 11.5H12V10H16V11.5Z"
      fill="currentColor"
    />
  </svg>
);
