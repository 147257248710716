export const User = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C4.03 0 0 4.03 0 9C0 13.17 2.84 16.67 6.69 17.69L9 20L11.31 17.69C15.16 16.67 18 13.17 18 9C18 4.03 13.97 0 9 0ZM9 2C10.66 2 12 3.34 12 5C12 6.66 10.66 8 9 8C7.34 8 6 6.66 6 5C6 3.34 7.34 2 9 2ZM9 16.3C6.5 16.3 4.29 15.02 3 13.08C3.03 11.09 7 10 9 10C10.99 10 14.97 11.09 15 13.08C13.71 15.02 11.5 16.3 9 16.3Z"
      fill="currentColor"
    />
  </svg>
);
