export const Loader = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 71 71"
    width="71px"
    height="71px"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M35.722 8.875A27.18 27.18 0 007.988 35.5a28.289 28.289 0 0027.734 28.955A29.399 29.399 0 0065.675 35.61c-.388-26.625-24.96-30.896-24.96-30.896s25.127 2.108 27.179 30.84c.444 17.75-13.756 33.004-32.172 33.393-18.305.443-34.058-14.256-34.39-33.282C.721 16.53 15.974.333 35.666 0c0 0 4.715 0 4.825 4.66 0 4.437-4.77 4.215-4.77 4.215z"
    />
  </svg>
);
